import React from 'react';
import Grid from '@mui/material/Grid';

import EscriptureDocs from './EscriptureDocs';
import HealthDocs from './HealthDocs';

const Documents = props => {
  const styles = {
    container: {
      marginTop: 30,
      marginBottom: 50
    },
    containers: {
      // border: '1px red solid',
      padding: 20,
      width: '100%'
    }
  }

  return (
    <Grid 
      container
      direction='row'
      justifyContent='space-evenly'
      alignItems="flex-start"
      style={styles.container}
    >
      <Grid item xs={0} md={1} />
      <Grid 
        item
        xs={12}
        md={5}
        style={styles.containers}
      >
        <EscriptureDocs />
      </Grid>
      <Grid 
        item 
        xs={12}
        md={5}
        style={styles.containers}
      >
        <HealthDocs />
      </Grid>
      <Grid item xs={0} md={1} />
    </Grid>
  )
}

export default Documents;