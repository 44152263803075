import React from 'react';

import HomeImage from './Home/HomeImage';
import BuddhaWords from './Home/BuddhaWords';
import Documents from './Home/Documents';
import Events from './Home/Events';

const Home = props => {
  
  return (
    <React.Fragment>
      <HomeImage />
      <BuddhaWords />
      <Documents />
      <Events />
    </React.Fragment>
  )
}

export default Home;