import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { getNewEvents } from '../../util/apiUtils';

const Events = props => {
  const [ events, setEvents ] = useState([]);

  useEffect(() => {
    const getEvents = async() => {
      const result = await getNewEvents();
      const allEvents = result.events;
      if (Array.isArray(allEvents) && allEvents.length > 0) {
        setEvents(allEvents);
      }
    }
    
    getEvents();
  }, []);

  const styles = {
    title: {
      color: '#999999',
      marginBottom: 20
    },
    eventPhoto: {
      width: '100%'
    },
    eventContainer: {
      paddingLeft: 20,
      paddingRight: 20
    },
    content: {
      width: '100%',
      maxHeight: 250
    },
    eventLink: {
      textDecoration: 'none'
    }
  }

  return (
    <Grid 
      container
      direction='row'
    >
      <Grid 
        item
        container
        justifyContent='center'
        xs={12}
      >
        <Typography
          variant="h4"
          component="div"
          style={styles.title}
        >
          活動花絮
        </Typography>
      </Grid>
      <Grid 
        item
        xs={12}
      >
        <Grid
          container 
          direction='row'
          justifyContent='space-around'
          spacing={3}
          style={styles.eventContainer}
        >
        {
          events.map(function(event) {
            return (
              <Grid 
                item
                container
                xs={12}
                sm={6}
                md={4}
                key={event.photoId}
              >
                <Grid
                  item
                > 
                  <Grid
                    item
                    container
                    xs={12}
                  > 
                    <Typography
                      component="div"
                      style={styles.content}
                      noWrap
                      xs={12}
                    >
                      <Link style={styles.eventLink} to={`/blog/${event.photoId}`}>
                        <img src={event.photoUrl} style={styles.eventPhoto} alt={event.title} />
                      </Link>
                    </Typography>

                    <Typography
                      component="div"
                      style={styles.content}
                      noWrap
                      xs={12}
                    >
                      {event.title}
                    </Typography>

                    { event.date && (
                      <Typography
                        component="div"
                        style={styles.content}
                        noWrap
                        xs={12}
                      >
                        日期: {event.date}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Events;