import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

import { navItems, pageLinks, menuDrawerWidth} from '../../util/constants';

const MenuDrawer = props => {
  const { container, mobileOpen, setMobileOpen } = props;
  const [ openAbout, setOpenAbout ] = useState(false);

  const styles = {
    logoImage: {
      height: 70
    },
    navLink: {
      textDecoration: 'none', 
      color: '#000000'
    }
  }

  const needToCloseDrawer = link => {
    if (link !== 'about') {
      setMobileOpen(false);
    } else {
      setOpenAbout(!openAbout);
    }
  }

  const closeDrawer = _ => {
    setMobileOpen(false);
  }

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <NavLink to="/">
          <img src='/img/logo.png' alt="FYCD Logo" style={ styles.logoImage } />
        </NavLink>
      </Typography>

      <Divider />

      <List>
        <ListItem key={990} disablePadding onClick={needToCloseDrawer.bind(this, 'about')}>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary='關於我們' />
          </ListItemButton>
        </ListItem>

        { openAbout && (
          <React.Fragment>
            <Link key={991} href='/about' style={styles.navLink}>
              <ListItem disablePadding onClick={needToCloseDrawer}>
                <ListItemButton sx={{ textAlign: 'center', borderTop: '2px solid grey' }}>
                  <ListItemText primary='關於我們' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link key={992} href='/history' style={styles.navLink}>
              <ListItem disablePadding onClick={needToCloseDrawer}>
                <ListItemButton sx={{ textAlign: 'center', borderTop: '1px dotted grey', borderBottom: '2px solid grey' }}>
                  <ListItemText primary='基金會的原由' />
                </ListItemButton>
              </ListItem>
            </Link>
          </React.Fragment>
        )}

        {navItems.slice(1).map((item, i) => (
          <Link key={i} href={pageLinks.slice(1)[i]} style={styles.navLink}>
            <ListItem key={item} disablePadding onClick={needToCloseDrawer}>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={closeDrawer}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: menuDrawerWidth },
      }}
    >
      {drawer}
    </Drawer>
  )
}

export default MenuDrawer;