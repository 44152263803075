import React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

const UserAvatar = props => {
  const { user } = props;

  if (user) { 
    return (
      <Button key="theUser" sx={{ color: '#fff' }} onClick={props.onClick}>
        <Avatar alt={user.name} src={user.picture} />
      </Button>
    )
  } else {
    return null;
  }
}

export default UserAvatar;