import React from 'react';
import Typography from '@mui/material/Typography';

const AboutHeader = props => {
  const styles = {
    title: {
      marginTop: 20
    },
    aboutImage: {
      width: '100%'
    }
  }

  return (
    <React.Fragment>
      <Typography
        variant="h4"
        component="div"
        style={styles.title}
      >
        發一崇德美國舊金山光慧文教基金會
      </Typography>

      <img style={styles.aboutImage} src='/img/aboutus/aboutus.jpg' alt='about-image' />
    </React.Fragment>
  )
}

export default AboutHeader;