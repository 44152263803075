import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import { UserContext } from '../../provider/UserProvider';

import { verifyUserByToken } from '../../util/apiUtils';

const Footer = props => {
  const { userData: [user, setUser] } = useContext(UserContext);
  const styles = {
    footer: {
      backgroundColor: '#a2101d',
      minHeight: 120,
      marginTop: 40
    },
    imageContainer: {
      height: 35
    },
    image: {
      height: 70, 
      position: 'relative', 
      top: -35
    },
    text: {
      color: '#ffffff',
      marginTop: 10
    },
    contactUs: {
      marginTop: 10,
      marginBottom: 30,
      color: '#ffffff',
      borderColor: '#ffffff'
    }
  }

  // revalidate user onload
  useEffect(() => {
    const token = window.localStorage.getItem('token');

    const validateUser = async() => {
      if (token) {
        const user = await verifyUserByToken();
  
        if (user) {
          setUser(user.user);
        } else {
          setUser({});
        }
      }
    }

    validateUser();
  }, [])

  const getYear = _ => {
    const today = new Date();
    return today.getFullYear();
  }

  return (
    <Grid 
      container 
      style={styles.footer} 
      direction='column' 
      justifyContent="center"
      alignItems="center"
    >
      <Grid item style={ styles.imageContainer }>
        <img style={ styles.image } src={'/img/logo.png'} alt='FYCD Logo' />
      </Grid>
      <Grid item>
        <Typography
          component="div"
          style={styles.text}
        >
          &copy; 1990 - {getYear()} 發一崇德舊金山光慧文教基金會
        </Typography>
      </Grid>
      <Grid item>
        <Link href='/contactus'>
          <Button 
            variant="outlined"
            size="large"
            style={styles.contactUs}
          >聯絡我們</Button>
        </Link>
      </Grid>
    </Grid>
  )
}

export default Footer;