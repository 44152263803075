import React from 'react';
import { Outlet } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';

import Navigation from './common/Navigation';
import Footer from './common/Footer';

const RootApp = () => {
  return (
    <React.Fragment>
      <Navigation />
      <Toolbar />
      <Outlet />
      <Footer />
    </React.Fragment>
  )
};

export default RootApp;