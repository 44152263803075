import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';

import MenuDrawer from './MenuDrawer';
import UserAvatar from './Navigation/UserAvatar';
import StanDialog from './StanDialog';
import { UserContext } from '../../provider/UserProvider';

import '../../css/Navigation.css';

import { navItems, pageLinks } from '../../util/constants'; 
import { handleGoogleLogin, logoutUser } from '../../util/loginUtil';
import { getGoogleProfile } from '../../util/apiUtils';
  
const Navigation = props => {
  const { window } = props;
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const [ anchorAboutMeEl, setAnchorAboutMeEl ] = useState(null);
  const openAboutMe = Boolean(anchorAboutMeEl);
  const [ anchorMeEl, setAnchorMeEl ] = useState(null);
  const openMe = Boolean(anchorMeEl);
  const { userData: [user, setUser] } = useContext(UserContext);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('');
  const [ dialogChildren, setDialogChildren ] = useState('');

  const handleAboutMeClick = (event) => {
    setAnchorAboutMeEl(event.currentTarget);
  };

  const handleAboutMeClose = () => {
    setAnchorAboutMeEl(null);
  };

  const handleMeClick = (event) => {
    setAnchorMeEl(event.currentTarget);
  };

  const handleMeClose = () => {
    setAnchorMeEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  const styles = {
    navLink: {
      textDecoration: 'none', 
      color: '#000000'
    },
    bar: { 
      color: '#ffffff', 
      paddingLeft: 8
    },
    loginLink: {
      color: '#00ff00'
    }
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => validateGoogleLogin(tokenResponse),
    flow: 'implicit'
  });

  const validateGoogleLogin = async (tokenResponse) => {
    // console.log('-------tokenResponse:', tokenResponse)
    const token = tokenResponse.access_token;
    const theUser = await handleGoogleLogin({ token });

    if (theUser) {
      if (window) {
        window.localStorage.setItem('token', theUser.jwt);
      }

      try {
        const profile = await getGoogleProfile(token);
        theUser.picture = profile.picture;
      } catch (e) {
        console.error('--- error getting Google profile pic');
      }
      setUser(theUser);
    } else {
      setShowDialog(true);
      setDialogTitle('對不起，登入有誤');
      setDialogChildren(
        <div>請稍候再試一下</div>
      );
    }
  }

  const handleCloseDialog = _ => {
    setShowDialog(false);
  }

  const handleLogout = _ => {
    handleMeClose();
    googleLogout();
    logoutUser();
    setUser({});
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" style={{ backgroundColor: '#a2101d', boxShadow: 'none' }}>
        <Toolbar style={{ border: '10px solid white', borderLeft: 'none', borderRight: 'none', paddingRight: 0 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography sx={{ display: { sm: 'none' }, color: '#fff', position: 'absolute', right: 0 }}>
            <UserAvatar
              user={user}
            />
          </Typography>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            style={{ height: 50, position: 'relative', top: -10 }}
          >
            <NavLink to="/">
              <img src='/img/logo.png' alt="FYCD Logo" style={{ height: 70 }} />
            </NavLink>
          </Typography>

          <Box 
            sx={{ display: { xs: 'none', sm: 'block' } }}
            style={{ color: 'black'}}
          >
            <Link style={styles.navLink}>
              <Button sx={{ color: '#fff' }} onClick={handleAboutMeClick}>
                關於我們
              </Button>
            </Link>

            <Menu
              id="aboutUsGroup"
              aria-labelledby="About Us"
              anchorEl={anchorAboutMeEl}
              open={openAboutMe}
              onClose={handleAboutMeClose}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <NavLink to='/about' style={styles.navLink}>
                <MenuItem onClick={handleAboutMeClose}>關於我們</MenuItem>
              </NavLink>
              <NavLink to='/history' style={styles.navLink}>
                <MenuItem onClick={handleAboutMeClose}>基金會的原由</MenuItem>
              </NavLink>
            </Menu>

            {
              navItems.slice(1).map((item, i) => (
                <NavLink key={i} to={pageLinks.slice(1)[i]}>
                  <Button key={item} sx={{ color: '#fff' }}>
                    {item}
                  </Button>
                </NavLink>
              ))
            }

            {
              user && user.name ? (
                <>
                  <UserAvatar user={user} onClick={handleMeClick} />
                  <Menu
                    id="MeGroup"
                    aria-labelledby="Me"
                    anchorEl={anchorMeEl}
                    open={openMe}
                    onClose={handleMeClose}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <NavLink to='/myClasses' style={styles.navLink}>
                      <MenuItem onClick={handleMeClose}>課表</MenuItem>
                    </NavLink>

                    {
                      user.admin && (
                        <NavLink to='/admin' style={styles.navLink}>
                          <MenuItem onClick={handleMeClose}>Admin</MenuItem>
                        </NavLink>
                      )
                    }

                    <Button key="theUser" sx={{ color: '#000000' }} onClick={handleLogout}>
                      登出
                    </Button>
                  </Menu>
                </>
              ) : (
                <>
                  <span style={styles.bar}>
                    |
                  </span>

                  <NavLink key={999} to={'#'}>
                    <Button sx={styles.loginLink} onClick={loginWithGoogle}>
                      登入
                    </Button>
                  </NavLink>
                </>
              )
            }
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <MenuDrawer 
          container={container}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
        />
      </Box>

      <StanDialog
        open={ showDialog } 
        handleClose={ handleCloseDialog }
        dialog={{ title: dialogTitle }}      // title, description
        children={ dialogChildren }
      />
    </Box>
  );
}

export default Navigation;