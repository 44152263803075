import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ExperienceContent = props => {
  const { experience } = props;

  const styles = {
    title: {
      marginBottom: 10
    },
    content: {
      marginBottom: 20,
      width: '100%'
    }
  }
  
  return (
    <Grid
      container
      direction='row'
    >
      <Typography
        variant="h5"
        component="div"
        style={styles.title}
      >
        {experience.title}
      </Typography>

      <Typography
        component="div"
        style={styles.content}
      >
        <div dangerouslySetInnerHTML={{__html: experience.content}} />
      </Typography>
    </Grid>
  )
}

export default ExperienceContent;