import React from 'react';
import Toolbar from '@mui/material/Toolbar';

export default function Dashboard() {
  return(
    <React.Fragment>
      <Toolbar />
      <h2>Dashboard</h2>
    </React.Fragment>
  );
}