import axios from 'axios';

import { 
  FYCD_API_HOSTNAME_PROD, 
  FYCD_API_HOSTNAME_LOCAL,
  RECAPTCHA_SITE_KEY_LOCAL,
  RECAPTCHA_SITE_KEY_PROD,
  DEFAULT_NUMBER_OF_EVENT
} from '../util/constants';

const getRandomBuddhaWords = async () =>  {
  const apiUrl = getApiUrl();

  const response = await callApi({
    url: `${apiUrl}/api/v1/buddhawords?count=1&random=true&language=tw`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getRandomEscriptures = async () =>  {
  const apiUrl = getApiUrl();

  const response = await callApi({
    url: `${apiUrl}/api/v1/documents?random=true&language=tw&category=scripture`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getRandomHealths = async () =>  {
  const apiUrl = getApiUrl();

  const response = await callApi({
    url: `${apiUrl}/api/v1/documents?random=true&language=tw&category=health`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getNewEvents = async ({count, offset}) =>  {
  const apiUrl = getApiUrl();

  let num = DEFAULT_NUMBER_OF_EVENT;
  let offsetNum = 0;

  if (count) {
    num = count;
  }
  if (offset) {
    offsetNum = offset;
  }

  const response = await callApi({
    url: `${apiUrl}/api/v1/events?count=${num}&offset=${offsetNum}`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getPhotoByPhotoId = async ({ photoId }) =>  {
  const apiUrl = getApiUrl();

  const response = await callApi({
    url: `${apiUrl}/api/v1/events/${photoId}`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getExperienceByText = async (searchText) => {
  const apiUrl = getApiUrl();
  const text = searchText.length > 0 ? `&text=${searchText}` : '';
  const response = await callApi({
    url: `${apiUrl}/api/v1/documents?count=30${text}&category=experience`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getRecipesBySearchTextAndType = async ({searchText, type}) => {
  const apiUrl = getApiUrl();
  const text = searchText.length > 0 ? `&text=${searchText}` : '';
  const theType = type > 0 ? `&type=${type}` : '';
  const response = await callApi({
    url: `${apiUrl}/api/v1/recipes?count=100${text}${theType}`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const getRecipesById = async ({id}) => {
  const apiUrl = getApiUrl();
  const response = await callApi({
    url: `${apiUrl}/api/v1/recipes/${id}`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const saveContactUs = async ({data}) => {
  const apiUrl = getApiUrl();
  const response = await callApi({
    url: `${apiUrl}/api/v1/contactus`,
    method: 'POST',
    headers: window.localStorage.getItem('token'),
    params: data
  });

  return response.data;
}

const getCalendarListByYearAndMonth = async ({month, year}) => {
  const apiUrl = getApiUrl();
  const response = await callApi({
    url: `${apiUrl}/api/v1/calendar/${year}/${month}`,
    method: 'GET',
    headers: window.localStorage.getItem('token')
  });

  return response.data;
}

const loginWithGoogle = async ({ token }) => {
  try {
    const apiUrl = getApiUrl();
    const response = await callApi({
      url: `${apiUrl}/api/v1/login/google`,
      method: 'POST',
      headers: window.localStorage.getItem('token'),
      params: {
        access_token: token
      }
    });

    return response.data;
  } catch (e) {
    console.error('---------loginWithGoogle:', e);
    return {
      error: e.message
    }
  }
}

const getGoogleProfile = async (token) => {
  try {
    const response = await callApi({
      url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });

    return response.data;
  } catch (e) {
    console.error('---------getGoogleProfile:', e);
    return {
      error: e.message
    }
  }
}

const verifyUserByToken = async () => {
  console.log('-------verifyUserByToken...');
  try {
    const apiUrl = getApiUrl();
    const response = await callApi({
      url: `${apiUrl}/api/v1/user/token`,
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('token')
      }
    });

    return response.data;
  } catch (e) {
    console.error('---------verifyUser error:', e);
    return {
      error: e.message
    }
  }
}

const callApi = ({url, method, params, headers}) => {
  let axiosOptions = {
    method: method,
    url: url,
    data: params
  };

  if (headers) {
    axiosOptions.headers = headers;
  }
  
  return axios(axiosOptions)
};

const isLocal = _ => {
  return (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('127.0.0.1') > -1);
}

const getApiUrl = _ => {
  if (isLocal()) {
    return `http://${FYCD_API_HOSTNAME_LOCAL}:2001`;
  } else {
    return `https://${FYCD_API_HOSTNAME_PROD}`;
  }
}

const getRecaptchaSiteKey = _ => {
  return isLocal() ? RECAPTCHA_SITE_KEY_LOCAL : RECAPTCHA_SITE_KEY_PROD;
}

export {
  callApi,
  getRandomBuddhaWords,
  getRandomEscriptures,
  getRandomHealths,
  getNewEvents,
  getPhotoByPhotoId,
  getExperienceByText,

  getRecipesBySearchTextAndType,
  getRecipesById,

  getCalendarListByYearAndMonth,

  loginWithGoogle,
  getGoogleProfile,

  verifyUserByToken,

  saveContactUs,

  getRecaptchaSiteKey,

  isLocal
}