import React from 'react';
import { Navigate } from 'react-router-dom';

export default function Admin({token}) {
  if (!token) {
    return <Navigate to='/login?next=preferences' />;
  }

  return(
    <h2>Admin</h2>
  );
}